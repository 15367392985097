import React, {useState} from 'react'
import clsx from 'clsx'
import {
  Icons,
  Typography,
  Section,
} from 'Components'
import * as styles from './Work.module.scss'

const workdata = [
  {
    company: 'techstars',
    role: 'front end software engineer',
    dateStart: 'August 2020',
    dateEnd: 'Present',
    description: [
      `I joined the Techstars engineering team in the middle of the pandemic and have been working with remote teams since. Since joining, I've developed new feature pages for Techstars.com and its podcasts pages, as well as worked on researching accessibility and maintaining our component library.`,
      `My day to day includes working closely with product, data and design teams to build products and features from the ground up. This involves researching technical options and working to implement optimal solutions, as well as coding the initial iterations of the component and style library.`,
    ],
    url: 'url',
    logo: 'image',
  },
  {
    company: 'quartz',
    role: 'software engineer',
    dateStart: 'August 2015',
    dateEnd: 'November 2019',
    description: [
      `As part of the creative engineering team, I collaborated closely with engineers, designers, project managers and content editors to develop award winning interactives.`, `I lead the development on web projects with Cartier, Hennessey, and Citrix, which garnered recognition from Brandtale (Best of the Week) and awards from Digiday (Best Branded Content Series – B2B).`,
      `As our team grew, I contributed to building out internal tools, such as template generators and libraries to speed our development processes.`,
      `I also helped to spearhead the inaugural culture committee across the business team, as well as encourage growth initiatives within our engineering team through weekly skill sharing sessions.`
    ],
    url: 'url',
    logo: 'image',
  },
  {
    company: 'td ameritrade',
    role: 'web developer apprentice',
    dateStart: 'February 2015',
    dateEnd: 'May 2015',
    description: [
      `As part of my apprenticeship with TD Ameritrade's subsidiary company Investools, I developed marketing landing pages, building out sections of the front end and improving code quality through dozens of automated browser tests.`,
      `I joined a second team with needs for a single page application that monitored uptime performance and statuses for maintenance, allowing quicker access to issue reporting. Upon launching, I developed training documentation and held sessions with teammates on how to best maintain and utilize the dashboard.`
    ],
    url: 'url',
    logo: 'image',
  },
  {
    company: 'general assembly',
    role: 'web developer',
    dateStart: 'August 2015',
    dateEnd: 'August 2015',
    description: [
      `I enrolled in the Web Development Immersive program to gain new skills in programming. This four month program included full-time classes, over 100 hours of coursework, and four complete full-stack applications, built mainly with Ruby (Rails and Sinatra), Javascript (Backbone) and CSS.`,
    ],
    url: 'url',
    logo: 'image',
  },
  {
    company: 'cspace',
    role: 'senior consultant',
    dateStart: 'August 2007',
    dateEnd: 'August 2014',
    description: [
      `Being a part of one of the leading agencies in consumer research sharpened my skills in designing research, asking questions and most importantly, listening. I managed multiple relationships with both clients and their consumers, working to tie business needs with effective customer solutions.`,
      `As a lead storyteller on multiple accounts, I also flexed creative skills in visualizing data in interesting ways, ensuring that the stories of consumers were told in an impactful and meaningful way.`
    ],
    url: 'url',
    logo: 'image',
  }
]

const Work = ({
	className: classNameProp,
	children,
}) => {
  const className = clsx(
    styles.sectionWork,
    classNameProp,
  )

  const [activeIndex, setActiveIndex] = useState(0);

  const [companyData, setCompanyData] = useState(workdata[0])

  const renderData = (idx) => {
    setCompanyData(workdata[idx])
  }

  const workItemHandler = (idx) => {
    setActiveIndex(idx)
    renderData(idx)
  }

	return (
		<Section className={ className }>
      <div className={styles.WorkContainer}>
        <ul className={styles.WorkList}>
          { workdata.map((work, idx) => (
            <li
              key={work.company}
              onClick={() => workItemHandler(idx)}
              className={
                clsx(styles.WorkItem,
                activeIndex === idx ? styles.active : '',
              )}
            >
              <Typography variant="h2" align="right" size="sm">{work.company}</Typography>
            </li>
          ))}
        </ul>
        { companyData ? (
          <div className={styles.WorkDetail}>
            <Typography className={styles.WorkDetailCompany} variant="h1" align="left">
              <span className={styles.companyRole}><Typography bold>{companyData.role}</Typography></span>
              <span className={styles.companyName}>{` @ ${companyData.company}`}</span>
            </Typography>
            <br></br>
            {/* <Typography className={styles.WorkDetailDates} bold variant="p" size="xs" align="left">{`${companyData.dateStart} - ${companyData.dateEnd}`}</Typography> */}
            { companyData.description.map(item => (
              <Typography className={styles.WorkDetailDescription} variant="p" align="left">
                {item}
              </Typography>
            ))}
          </div>
        ) : 'Loading' }
      </div>
		</Section>
	)
}

export default Work