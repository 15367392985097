import React, { useState } from 'react'
import clsx from 'clsx'
import {
  Typography,
  Icons,
  Section,
} from 'Components'
import {
  ProjectItem,
} from './components'
import * as styles from './Projects.module.scss'


const projectList = [
  {
    project: 'citrix',
    description: 'The Citrix WX101 course is a new format using intuitive tapping behavior to progress through stories around work experience. As each course contains over 100 slides, this required thoughtful and future-proof templating that would consume data in the most efficient way possible.',
    video: 'https://player.vimeo.com/video/567283447'
  },
  {
    project: 'remind',
    description: `Remind was launching their latest product for online student coaching. As a freelance front end engineer, I worked on finessing front end components and responsiveness for the marketing site.`,
    video: 'https://player.vimeo.com/video/567283679'
  },
  {
    project: 'cartier',
    description: `The beauty behind the creative process is celebrated in this 5 piece interactive, highlighting three artists' journey towards inspiration. The subtle animation of shadow and light and bold photography earned this piece a Brandtale's Best of the Week.`,
    video: 'https://player.vimeo.com/video/567283702'
  },
  {
    project: 'lincoln',
    description: `Fondly known as The Cube, this execution for Lincoln shows off the MKZ's top features, as well as guest appearances by Matthew McConaughey. Users can engage with the cube via touch, keypress and clicks.`,
    video: 'https://player.vimeo.com/video/567283603'
  },
  {
    project: 'brightline',
    description: 'While crisis management can be a serious topic, educating others around it can still be engaging. This interactive for Brightline uses flip card trivia written primarily in CSS to encourage user interaction.',
    video: 'https://player.vimeo.com/video/567296629'
  },
  {
    project: 'api',
    description: 'This interactive highlights the 6 steps of the oil supply chain and where oil plays a role in everyday life. Audio recordings played on a custom coded audio player, light touch animation and bright visual elements help bring this story to life.',
    video: 'https://player.vimeo.com/video/570411117'
  }
]

const Projects = ({
	className: classNameProp,
}) => {
	const className = clsx(
		styles.sectionProjects,
		classNameProp,
	)

  const [activeIndex, setActiveIndex] = useState(null);
  const activeHandler = (idx, activeIndex) => {
    if (activeIndex === idx) {
      setActiveIndex(null)
      return
    }
    setActiveIndex(idx)
  }

	return (
		<Section className={ className }>
      <div className={styles.ProjectsContentGrid}>
        { projectList.map((project, idx) => (
          <ProjectItem
            key={project.project}
            className={idx === activeIndex ? styles.active : ''}
            onClickHandler={() => activeHandler(idx, activeIndex)} 
            project={project.project}
            description={project.description}
            active={idx === activeIndex ? true : false}
            video={project.video}
          />
        ))}
      </div>
		</Section>
	)
}

export default Projects
