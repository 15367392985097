import React from 'react';
import Icons from './Icons';

export default function Code({ className, viewBox } = '') {
  return (
    <Icons 
      className={className}
      viewBox={viewBox}
      path={
        (
          <path d="M53.97 494.87L29.13 30.647l-29.13 2L19.13 494.87zM242.81 108.123c64.409 0 101.41 35.173 99.583 106.435l-73.546 1.827c1.37-38.372-15.074-42.026-24.21-42.026c-8.68 0-23.298 2.741-23.298 23.297c0 50.705 125.166 47.964 125.166 150.288c0 57.557-44.768 84.05-101.868 84.05c-58.928 0-110.547-20.099-104.608-108.262h77.199c-.914 35.631 6.854 48.422 26.496 48.422c15.075 0 21.926-8.68 21.926-23.297c0-52.076-123.337-53.902-123.337-149.374c0-54.816 36.087-91.36 100.497-91.36zM420 0l-39.978 3.262v77.354L347.528 48.27l-30.993 36.347V6.466L56.647 26.647l26.84 468.224L420 512z" />
        )
      } 
    />
  )
}