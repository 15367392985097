import React from 'react';
import Icons from './Icons';

export default function Code({ className, viewBox } = '') {
  return (
    <Icons 
      className={className}
      viewBox={viewBox}
      path={
        (
          <path d="M47,5v40L30.619,5H47z M17.762,36.579H25L28.429,45h7.238L25,18.801L17.762,36.579z M3,5v40L19.381,5H3z"/>
        )
      } 
    />
  )
}
