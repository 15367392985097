// extracted by mini-css-extract-plugin
export var Typography = "Typography-module--Typography--GsvQq";
export var bold = "Typography-module--bold--FTcvN";
export var center = "Typography-module--center--5Bs7K";
export var italic = "Typography-module--italic--NDWiP";
export var left = "Typography-module--left--RDRBL";
export var lg = "Typography-module--lg--4KDHi";
export var md = "Typography-module--md--cZn2S";
export var p = "Typography-module--p--+Y66T";
export var primary = "Typography-module--primary--ZQ7Xl";
export var right = "Typography-module--right--ZsAdT";
export var sm = "Typography-module--sm--WxOlk";
export var tag = "Typography-module--tag--Ervth";
export var underline = "Typography-module--underline--JSV35";
export var xs = "Typography-module--xs--7SZYm";