import React from 'react'
import clsx from 'clsx'
import {
  Typography,
	Video,
	Icons
} from 'Components'
import * as styles from './ProjectItem.module.scss'

const ProjectItem = ({
	active,
	className: classNameProp,
	project,
	description,
	onClickHandler,
	...other
}) => {
	const className = clsx(
		'ProjectItem',
		styles.ProjectItem,
		styles[active ? 'active' : ''],
		classNameProp,
	)

	return (
		<div className={ className } onClick={onClickHandler}>
			<Typography className={styles.ProjectItemName} type="primary" variant="p" size="lg" bold align="left">
				{project}
			</Typography>
			<Typography className={styles.ProjectItemDescription} variant="p" align="left">
				{description}
			</Typography>
			<Video
				className={styles.ProjectItemVideo} 
				source={other.video}
			/>
			<Icons.Expand className={styles.ExpandIcon} viewBox="0 0 512 640" />
		</div>
	)
}

export default ProjectItem