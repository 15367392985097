import React from 'react'
import clsx from 'clsx'
import * as styles from './Section.module.scss'

const Section = ({
	className: classNameProp,
	containerClass,
	children,
}) => {
	const className = clsx(
		'Section',
		styles.Section,
		classNameProp,
	)

	return (
		<div className={ className }>
      <div className={clsx(styles.sectionContainer, containerClass)}>
  			{ children }
      </div>
		</div>
	)
}

export default Section