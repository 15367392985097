// extracted by mini-css-extract-plugin
export var SkillsGrid = "Skills-module--SkillsGrid--OmRyh";
export var sectionSkills = "Skills-module--sectionSkills--cqTn3";
export var skillIcon = "Skills-module--skillIcon--azDnv";
export var skillSet = "Skills-module--skillSet--WdCT0";
export var skillText = "Skills-module--skillText--JrgG7";
export var skillTitle = "Skills-module--skillTitle--RY9kA";
export var skillsCategory = "Skills-module--skillsCategory--+AVUe";
export var skillsCategoryContent = "Skills-module--skillsCategoryContent--TMU4y";
export var skillsCategoryIcons = "Skills-module--skillsCategoryIcons--C-Lu0";
export var skillsCategoryTitle = "Skills-module--skillsCategoryTitle--Zq6O5";