/* Credit: Female by H Alberto Gongora from the Noun Project */

import React from 'react';
import Icons from './Icons';

export default function Woman({ className, viewBox } = '') {
  return (
    <Icons 
      className={className}
      viewBox={viewBox}
      path={
        (
          <>
            <path d="M94.532,92.576c-1.239-3.239-5.452-13.955-7.627-15.866c-3.703-3.253-9.043-4.181-13.754-5.001  c-1.527-0.266-3.018-0.525-4.34-0.848c3.813-0.536,9.124-1.259,12.972-1.776c0.437-0.059,0.827-0.306,1.067-0.676  c0.24-0.37,0.306-0.827,0.181-1.25c-5.293-17.923-5.274-19.947-5.184-29.674c0.014-1.446,0.029-3.057,0.029-4.91  C77.875,19.996,65.698,5.374,50,5.374c-6.496,0-12.581,2.664-17.599,7.703c-5.584,5.608-9.316,13.862-9.287,20.538  c0.042,9.649-0.489,22.141-4.997,33.191c-0.175,0.427-0.144,0.911,0.083,1.312c0.226,0.402,0.624,0.678,1.079,0.75  c3.635,0.576,8.705,1.399,12.311,2.016c-1.406,0.319-3.023,0.551-4.681,0.789c-4.978,0.714-10.621,1.522-14.285,5.09  c-1.986,1.933-5.985,12.609-7.163,15.835c-0.169,0.463-0.101,0.98,0.181,1.384c0.282,0.404,0.745,0.645,1.238,0.645h86.241  c0.497,0,0.963-0.245,1.245-0.655S94.709,93.041,94.532,92.576z M30.668,38.152c0.818-0.689,2.788-2.645,4.341-6.533  c1.327-3.323,1.901-6.055,2.129-8.062c1.649,2.706,4.692,6.528,10.269,8.274c10.532,3.296,17.74-2.088,22.391,4.807l0.008,0.498  c0,16.623-8.879,30.146-19.793,30.146C39.979,67.282,30.832,53.45,30.668,38.152z"/>
          </>
        )
      } 
    />
  )
}