/* Created by Me!*/

import React from 'react';
import Icons from './Icons';
import clsx from 'clsx'
import * as styles from './Icons.module.scss'

export default function Code({ className, viewBox }) {
  return (
    <Icons
      className={clsx(className, 'Avatar')}
      viewBox={viewBox}
      path={
        (
          <>
            <g id="RightArm">
                <rect x="186.2" y="235.6" transform="matrix(0.9465 -0.3226 0.3226 0.9465 -86.6668 83.5192)" className="st0" width="44.9" height="135.4"/>
              
                <ellipse transform="matrix(0.9465 -0.3226 0.3226 0.9465 -67.1661 73.0512)" className="st0" cx="186.8" cy="239.2" rx="22.1" ry="19.6"/>
              
                <ellipse transform="matrix(0.9465 -0.3226 0.3226 0.9465 -106.1676 93.9871)" className="st0" cx="230.5" cy="367.3" rx="22.1" ry="19.6"/>
            </g>
            <g id="LeftArm">
              
                <rect x="19.5" y="241.5" transform="matrix(0.9548 0.2973 -0.2973 0.9548 93.8184 1.4989)" className="st0" width="44.9" height="135.4"/>
              
                <ellipse transform="matrix(0.2973 -0.9548 0.9548 0.2973 -189.8379 231.1327)" className="st0" cx="62.1" cy="244.5" rx="19.6" ry="22.1"/>
              
                <ellipse transform="matrix(0.2973 -0.9548 0.9548 0.2973 -341.5396 283.5325)" className="st0" cx="21.9" cy="373.8" rx="19.6" ry="22.1"/>
            </g>
            <g id="Body">
              <rect x="47.8" y="277" className="st1" width="168.6" height="131.7"/>
              <ellipse className="st1" cx="132.1" cy="271.3" rx="84.3" ry="88.1"/>
            </g>
            <g id="Face">
              <circle id="Faceshape" className="st0" cx="125.2" cy="140.6" r="91.3"/>
              <g id="Eyes">
                <g id="OpenEyes" className="st2">
                  <circle className="st3" cx="107" cy="155.3" r="10.1"/>
                  <circle className="st3" cx="162.4" cy="145.2" r="10.1"/>
                  <path className="st3" d="M147.6,121.4c-0.7-0.2,3-8.3,13.7-9.9c10.7-1.6,16.6,5.1,16,5.4c-0.5,0.3-6.9-4.1-15.4-2.8
                    C153.2,115.4,148.2,121.6,147.6,121.4z"/>
                  <path className="st3" d="M82.1,137.9c-0.7-0.2,3-8.3,13.7-9.9c10.7-1.6,16.6,5.1,16,5.4c-0.5,0.3-6.9-4.1-15.4-2.8
                    C87.7,131.9,82.7,138,82.1,137.9z"/>
                </g>
                <g id="HappyClosed">
                  <path className="st4" d="M118.3,158.5c-0.5,0.8-8-6.5-16-4.5c-8,2-11.1,12-11.9,11.6c-1-0.5,0.6-15,10.4-17.5
                    C110.7,145.6,118.9,157.6,118.3,158.5z"/>
                  <path className="st4" d="M147.6,121.4c-0.7-0.2,3-8.3,13.7-9.9c10.7-1.6,16.6,5.1,16,5.4c-0.5,0.3-6.9-4.1-15.4-2.8
                    C153.2,115.4,148.2,121.6,147.6,121.4z"/>
                  <path className="st4" d="M82.1,137.9c-0.7-0.2,3-8.3,13.7-9.9c10.7-1.6,16.6,5.1,16,5.4c-0.5,0.3-6.9-4.1-15.4-2.8
                    C87.7,131.9,82.7,138,82.1,137.9z"/>
                  <path className="st4" d="M181.2,147.8c-0.7,0.6-6.1-8.3-14.4-8.3c-8.3,0-13.7,8.9-14.4,8.3c-0.8-0.7,4.2-14.4,14.4-14.4
                    C177,133.3,182,147,181.2,147.8z"/>
                </g>
                <g id="ClosedEyes" className="st2">
                  <path className="st3" d="M89.5,158.3c0.5-0.7,7.4,6.1,15,4.2c7.5-1.9,10.4-11.2,11.2-10.8c0.9,0.5-0.5,14-9.8,16.4
                    C96.7,170.4,88.9,159.1,89.5,158.3z"/>
                  <path className="st3" d="M147.6,121.4c-0.7-0.2,3-8.3,13.7-9.9c10.7-1.6,16.6,5.1,16,5.4c-0.5,0.3-6.9-4.1-15.4-2.8
                    C153.2,115.4,148.2,121.6,147.6,121.4z"/>
                  <path className="st3" d="M82.1,137.9c-0.7-0.2,3-8.3,13.7-9.9c10.7-1.6,16.6,5.1,16,5.4c-0.5,0.3-6.9-4.1-15.4-2.8
                    C87.7,131.9,82.7,138,82.1,137.9z"/>
                  <path className="st3" d="M149.2,144.5c0.5-0.7,7.4,6.1,15,4.2c7.5-1.9,10.4-11.2,11.2-10.8c0.9,0.5-0.5,14-9.8,16.4
                    C156.4,156.6,148.7,145.4,149.2,144.5z"/>
                </g>
              </g>
              <g id="Mouths">
                <path id="Frown" className="st6" d="M166.1,188.1c0.9-0.7-8.6-12-24-8.9c-15.4,3.1-19.7,17.2-18.7,17.5c0.9,0.3,7.2-10.4,19.4-12.9
                  C155.1,181.3,165.3,188.8,166.1,188.1z"/>
                <path id="Talk" className="st6" d="M160.9,178.2c3.4,4.5-4.2,21.6-17.4,24.2c-13.1,2.6-26.7-10-25.4-15.6c0.7-3,8.3-4.4,23.4-7.3
                  C153.4,177.2,159.4,176.1,160.9,178.2z"/>
                <path id="Smile" className="st4" d="M161.2,182c1,0.2-3.8,12.2-19.2,15.3c-15.4,3.1-24.4-6.1-23.6-6.7c0.8-0.5,10.4,5.4,22.6,3
                  C153.5,191.1,160.3,181.7,161.2,182z"/>
              </g>
            </g>
            <g id="Hair">
              <path className="st4" d="M209.5,114.4c-0.9-28-11.9-49.2-46.2-62.3C148,44.6,134.8,38,132,41.2c-2.6,2.9,3.9,14,12,20.9
                c6.5,5.5,9.9,4.8,26.8,13.9c5.2,2.6,39.5,22.6,36.6,25.1C208.7,103.6,210.2,107.9,209.5,114.4z"/>
              <path className="st4" d="M124.3,68.2c16.1,4.1,47.4,25.3,58.4,41.6c9.4,24.2,28.8,42.6,22.1,70.9c-4.9,21-20.8,32.4-19.4,33.9
                c1.4,1.5,19.6-7.4,31-26c1.9-3.1,16.6-28.2,8.6-57.6c-3.5-12.7-8-9.7-19.5-33.4c-15.5-44.8-46.4-67.5-88-56"/>
              <g>
                <path className="st4" d="M142.2,41.9C107,35.6,49.8,53.2,36.8,97.6c-12.7,24.2-0.8,86,12.5,94c1.8-0.6-7.6-17.9-0.3-36.5
                  c4.1-14.5,35.3-37.9,43.6-41.2c14.5-7.5,18.6-5.3,33.1-13.4c12-8.5,33.4-21.7,45.1-24.5"/>
                <path className="st4" d="M26.9,19.8C8.5,29.6,9,45.4,23.4,64c4.4,6.6,6.6,9.9,10.5,11.6c5.6,2.4,10.3,0.5,29.1-7
                  c24.6-9.9,28.4-11.4,30.2-16.3C97.9,27.4,67,2.6,49,0"/>
                <path className="st4" d="M58.3,212.1c-1.2-27.5,2.9-48.4,7-62.8c0,0,2.4-8.2,19.7-52.4l0,0c51.2-30.3,54.4-42.5,52.7-47.6
                  C121,30.8,61.8,39.9,36.2,78.4c-4.2,6.3-4.3,8.6-14,41.9C2.2,192.7-7.8,194.6,40.7,237.9"/>
              </g>
            </g>
          </>
        )
      } 
    />
  )
}