import React from 'react'
import clsx from 'clsx'
import {
  Typography,
  Section,
  Icons,
} from 'Components'
import * as styles from './Contact.module.scss'

const Contact = ({
	className: classNameProp,
	children,
}) => {
	const className = clsx(
		styles.SectionContact,
		classNameProp,
	)

	return (
		<Section className={ className } containerClass={styles.ContactContainer}>
      <Typography className={styles.ContactText} variant="h1" align="center">
        Let's get in touch!
      </Typography>
      <Typography 
				variant="p" 
				size="xs" 
				className={styles.Signature}
				align="left">
					karynn tran 2021
			</Typography>       
		</Section>
	)
}

export default Contact