import React from 'react'
import clsx from 'clsx'
import {
  Icons,
  Layout,
  Section,
  Typography,
} from 'Components'
import {
  About,
  Contact,
  Projects,
  Work,
  Skills,
} from 'Templates'
import * as styles from './index.module.scss'

const App = () => {
  return (
    <Layout>
      <Section className={styles.sectionIntro}>
        <div className={styles.introText}>
          <Typography variant="h1" className={styles.backgroundFirstName} type="primary">karynn</Typography>
          <Typography variant="h1" className={styles.backgroundLastName} type="primary">tran</Typography>
        </div>
        <div className={styles.foregroundName}>
          <Typography className={styles.introTagline} variant="p" size="sm" bold>
            software engineer
          </Typography>
        </div>
        <div className={styles.animatedAvatar}>
          <Icons.Avatar className={styles.introAvatar} viewBox="0 0 350 408.7"/>
        </div>
      </Section>
      <About />        
      <Work />
      <Projects/>
      <Skills />
      <Contact />
      <div className={styles.ContactIcons}>
        <a className={styles.ContactLink} href="mailto:karynn.tran@gmail.com">
          <Icons.Email className={styles.ContactIcon} viewBox="0 0 63 63" />
        </a>  
        <a className={styles.ContactLink} href="https://www.linkedin.com/in/karynneliotran/" target="_blank">
          <Icons.Linkedin className={styles.ContactIcon} viewBox="0 0 30 30" />
        </a>
        <a className={styles.ContactLink} href="https://github.com/karynntran" target="_blank">
          <Icons.Github className={styles.ContactIcon} viewBox="0 0 50 50" />
        </a>
      </div>
    </Layout>
  )
}

export default App