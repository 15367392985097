import React from 'react';
import Icons from './Icons';

export default function Code({ className, viewBox } = '') {
  return (
    <Icons 
      className={className}
      viewBox={viewBox}
      path={
        (
          <path d="M8.537 24L8.537 24c-1.933 0-3.5-1.567-3.5-3.5l0 0c0-1.933 1.567-3.5 3.5-3.5h2.5c.552 0 1 .448 1 1v2.5C12.037 22.433 10.47 24 8.537 24zM16.5 8H14c-.552 0-1-.448-1-1V2c0-.552.448-1 1-1h2.5C18.433 1 20 2.567 20 4.5l0 0C20 6.433 18.433 8 16.5 8zM8.5 8H11c.552 0 1-.448 1-1V2c0-.552-.448-1-1-1H8.5C6.567 1 5 2.567 5 4.5l0 0C5 6.433 6.567 8 8.5 8zM8.5 16H11c.552 0 1-.448 1-1v-5c0-.552-.448-1-1-1H8.5C6.567 9 5 10.567 5 12.5l0 0C5 14.433 6.567 16 8.5 16zM16 9A3 3 0 1016 15 3 3 0 1016 9z"/>       
        )
      } 
    />
  )
}