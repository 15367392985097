// extracted by mini-css-extract-plugin
export var WorkContainer = "Work-module--WorkContainer--E5f2a";
export var WorkDetail = "Work-module--WorkDetail--Z9ME+";
export var WorkDetailDates = "Work-module--WorkDetailDates--UJmVj";
export var WorkDetailDescription = "Work-module--WorkDetailDescription--AcA+b";
export var WorkItem = "Work-module--WorkItem--QUcUs";
export var WorkList = "Work-module--WorkList--fkVDw";
export var active = "Work-module--active--6jMsu";
export var companyName = "Work-module--companyName--inJS5";
export var companyRole = "Work-module--companyRole--yoxWo";
export var sectionWork = "Work-module--sectionWork--cqgDn";