import React from 'react'
import clsx from 'clsx'
import * as styles from './Typography.module.scss'

const Typography = ({
	className: classNameProp,
	children,
  variant = 'default',
	align = 'center',
	bold = false,
	underline = false,
	italic = false,
	type = 'secondary',
	size = ''
}) => {
	const className = clsx(
		'Typography',
		styles.Typography,
		classNameProp,
    styles[variant],
		styles[align],
		styles[bold ? 'bold' : ''],
		styles[italic ? 'italic' : ''],
		styles[underline ? 'underline' : ''],
		styles[type],
		styles[size],
	)

  const mapping = {
    'h1': 'h1',
		'h2': 'h2',
		'h3': 'h3',
		'h4': 'h4',
		'h5': 'h5',
		'h6': 'h6',
    'p': 'p',
    'tag': 'p',
		'default': 'default'
  }

  const Component = mapping[variant]

	return (
		<Component className={className}>
			{ children }
    </Component>
	)
}

export default Typography