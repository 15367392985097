import React from 'react';
import Icons from './Icons';

export default function Code({ className, viewBox } = '') {
  return (
    <Icons 
      className={className}
      viewBox={viewBox}
      path={
        (
          <path d="M 2.6289062 2 L 4.7109375 20 L 11.998047 22 L 19.287109 20 L 21.369141 2 L 2.6289062 2 z M 4.8710938 4 L 19.126953 4 L 17.457031 18.429688 L 12 19.925781 L 6.5429688 18.429688 L 4.8710938 4 z M 7.0078125 6 L 7.5449219 12.337891 L 14.480469 12.337891 L 14.244141 15.091797 L 12.023438 15.591797 L 9.7617188 15.09375 L 9.6757812 14.074219 L 7.6933594 14.074219 L 7.9140625 16.697266 L 11.960938 17.986328 L 12.001953 18 L 16.09375 16.697266 L 16.640625 10.261719 L 9.3515625 10.261719 L 9.1699219 8.0820312 L 16.824219 8.0820312 L 17.003906 6 L 7.0078125 6 z"/>
        )
      } 
    />
  )
}