import React from 'react';
import Icons from './Icons';

export default function Code({ className, viewBox } = '') {
  return (
    <Icons 
      className={className}
      viewBox={viewBox}
      path={
        (
          <path d="M7.684 0v8.035h7.775c.28 0 .502.236.502.483v7.802h8.025V.502A.502.502 0 0 0 23.484 0zm-.5.5L.086 7.607a.251.251 0 0 0 .178.428h6.92zm.5 8.035v7.283c0 .278.224.502.502.502h7.275V9.018c0-.278-.224-.482-.502-.483zm8.277 8.285v6.928c0 .224.271.336.43.178l7.095-7.106z" />
        )
      } 
    />
  )
}