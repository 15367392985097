import React from 'react'
import clsx from 'clsx'
import * as styles from './Icons.module.scss'

const Icons = ({
	className: classNameProp,
	viewBox="0 0 100 100",
	sizeBox,
  path,
}) => {
	const className = clsx(
		'Icons',
		styles.Icons,
		classNameProp,
	)

	return (
    <div className={className}>
			<svg viewBox={viewBox}>
	      { path }
			</svg>
    </div>
	)
}

export default Icons