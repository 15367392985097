// extracted by mini-css-extract-plugin
export var ContactIcon = "index-module--ContactIcon--C68e-";
export var ContactIcons = "index-module--ContactIcons--0CjL4";
export var ContactLink = "index-module--ContactLink--HLyEb";
export var animatedAvatar = "index-module--animatedAvatar--GJWT-";
export var backgroundFirstName = "index-module--backgroundFirstName--dSqJs";
export var backgroundLastName = "index-module--backgroundLastName--4cK7U";
export var blinking = "index-module--blinking--JuY54";
export var card = "index-module--card--YUCyb";
export var foregroundName = "index-module--foregroundName--R6Tgr";
export var introAvatar = "index-module--introAvatar--Pc1+s";
export var introTagline = "index-module--introTagline--XUQXy";
export var introText = "index-module--introText--VBHzR";
export var opening = "index-module--opening--ffhS4";
export var sectionIntro = "index-module--sectionIntro--wR2r1";
export var waving = "index-module--waving--D7WFw";