/* 
Created by Alax
from the Noun Project
*/

import React from 'react';
import Icons from './Icons';

export default function Code({ className, viewBox } = '') {
  return (
    <Icons 
      className={className}
      viewBox={viewBox}
      path={
        (
          <svg viewBox="0 0 100 125" className={className}>
          <g data-name="Group"><polygon data-name="Path" points="64.9 35.6 81.2 50 64.9 64.4 70.2 70.4 93.2 50 70.2 29.6 64.9 35.6"/><polygon data-name="Path" points="35.1 64.4 18.8 50 35.1 35.6 29.8 29.6 6.8 50 29.8 70.4 35.1 64.4"/><rect data-name="Path" x="15.8" y="46" width="68.5" height="8" transform="translate(-9.1 88.9) rotate(-78.4)"/></g>
        </svg>
        )
      } 
    />
  )
}