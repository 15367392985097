import React from 'react'
import clsx from 'clsx'
import * as styles from './Layout.module.scss'

const Layout = ({
	className: classNameProp,
	children,
}) => {
	const className = clsx(
		styles.Layout,
		classNameProp,
	)

	return (
		<div className={ className }>
			{ children }
		</div>
	)
}

export default Layout