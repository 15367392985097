import React from 'react'
import clsx from 'clsx'
import {
  Typography,
  Section,
  Icons,
} from 'Components'
import * as styles from './About.module.scss'

const About = ({
	className: classNameProp,
	children,
}) => {
	const className = clsx(
		styles.sectionAbout,
		classNameProp,
	)

	return (
		<Section className={ className }>
      <div className={ styles.AboutContainer }>
        <div className={styles.AboutContentGrid}>
          <div className={styles.AboutContentItem}>
            <div className={styles.ItemHeader}>
              <Icons.Avatar className={styles.AvatarIcon} viewBox="0 0 350 408.7"/>
              <Typography variant="tag">WHO I AM</Typography>
            </div>
            <div className={styles.ItemContent}>
              <Typography variant="p">
                she / her
              </Typography>
              <Typography variant="p">
                mama
              </Typography>
              <Typography variant="p">
                crafter
              </Typography>
            </div>

          </div>
          <div className={styles.AboutContentItem}>
            <div className={styles.ItemHeader}>
              <Icons.NewYork className={styles.AboutIcon} />
              <Typography variant="tag">WHERE I AM</Typography>
            </div>
            <div className={styles.ItemContent}>
              <Typography variant="p">
                I'm based out of Manhattan, New York City and have been collaborating remotely with global teams since 2020.
              </Typography>
            </div>
          </div>
          <div className={styles.AboutContentItem}>
            <div className={styles.ItemHeader}>
              <Icons.Code className={styles.AboutIcon} />
              <Typography variant="tag">WHAT I DO</Typography>
            </div>
            <div className={styles.ItemContent}>
              <Typography variant="p">
                I'm a software engineer and I build things for the web.
              </Typography>
            </div>
          </div>
        </div>
      </div>
		</Section>
	)
}

export default About