import React from 'react'
import clsx from 'clsx'
import {
  Typography,
  Section,
  Icons,
} from 'Components'
import * as styles from './Skills.module.scss'

const skills = [
  { "Language / Framework": [
    {
      skill: "Javascript",
      name: "Javascript",
      viewBox: "0 0 50 50"
    },
    {
      skill: "React",
      name: "React",
      viewBox: "0 0 30 30"
    },
    {
      skill: "Html",
      name: "HTML",
      viewBox: "0 0 25 25"
    },
    {
      skill: "Css3",
      name: "CSS",
      viewBox: "0 0 50 50"
    },
    {
      skill: "Backbone",
      name: "Backbone",
      viewBox: "0 0 256 318"
    },
    {
      skill: "Ruby",
      name: "Ruby",
      viewBox: "0 0 50 50"
    },
    {
      skill: "Gatsby",
      name: "Gatsby",
      viewBox: "0 0 48 48"
    },
  ]
},
{ 
  "Design": [
    {
      skill: "Sass",
      name: "Sass",
      viewBox: "0 0 50 50"
    },
    {
      skill: "Adobe",
      name: "Adobe Creative Suite",
      viewBox: "0 0 50 50"
    },
    {
      skill: "Figma",
      name: "Figma",
      viewBox: "0 0 25 25"
    },
    {
      skill: "Sketch",
      name: "Sketch",
      viewBox: "0 0 1024 1024"
    },
    {
      skill: "Storybook",
      name: "Storybook",
      viewBox: "0 0 420 512"
    },
  ]
  },
  {
    "Testing": [
      {
        skill: "Selenium",
        name: "Selenium",
        viewBox: "0 0 50 50"
      },
      {
        skill: "Jest",
        name: "Jest",
        viewBox: "0 0 256 283"
      },
    ]
  },
  {
    "Other": [
      {
        skill: "Contentful",
        name: "Contentful",
        viewBox: "0 0 24 24"
      },
      {
        skill: "Strapi",
        name: "Strapi",
        viewBox: "0 0 24 24"
      },
      {
        skill: "ConsumerInsight",
        name: "Insights research",
        viewBox: "0 0 25 25"
      },
      {
        skill: "Qualitative",
        name: "Qualitative Data",
        viewBox: "0 0 1025 1025"
      },
    ]
  }
]

const Skills = ({
	className: classNameProp,
}) => {
	const className = clsx(
		styles.sectionSkills,
		classNameProp,
	)
  
  const renderSkills = () => {
    return skills.map(skill => {
      const [[ key, values]] = Object.entries(skill)
      return (
        <div className={clsx(styles.skillsCategory, styles[key])}>
          <div className={styles.skillsCategoryContent}>
            <Typography className={styles.skillsCategoryTitle} variant="p" size="sm" align="left" bold>{key}</Typography>
            <div className={styles.skillsCategoryIcons}>
              {
                values.map(val => {
                  const IconType = Icons[val.skill]
                  return (
                    <span className={styles.skillSet}>
                      <IconType className={styles.skillIcon} viewBox={val.viewBox} />
                      <Typography className={styles.skillText} variant="p" size="xs" align="left">{val.name}</Typography>
                    </span>
                  )}
                )
              }
            </div>
          </div>
        </div>
      )
    })
  }
  
	return (
		<Section className={ className }>
      <div className={styles.skillTitle}>
        <Typography variant="h1" bold>What I've worked with</Typography>
      </div>
      <div className={styles.SkillsGrid}>
        { renderSkills() }
      </div>
		</Section>
	)
}

export default Skills