import {default as Adobe} from './Adobe'
import {default as Avatar} from './Avatar'
import {default as Backbone} from './Backbone'

import {default as Css3} from './Css3'
import {default as Code} from './Code'
import {default as ConsumerInsight} from './ConsumerInsight'

import {default as Contentful} from './Contentful'

import {default as Email} from './Email'
import {default as Expand} from './Expand'

import {default as Figma} from './Figma'
import {default as Gatsby} from './Gatsby'

import {default as Github} from './Github'
import {default as Html} from './Html'
import {default as Javascript} from './Javascript'
import {default as Jest} from './Jest'
import {default as Linkedin} from './Linkedin'
import {default as NewYork} from './NewYork'
import {default as React} from './React'
import {default as Ruby} from './Ruby'
import {default as Sass} from './Sass'
import {default as Sketch} from './Sketch'
import {default as Selenium} from './Selenium'
import {default as Storybook} from './Storybook'

import {default as Strapi} from './Strapi'
import {default as Qualitative} from './Qualitative'

import {default as Woman} from './Woman'

export default {
  Adobe,
  Avatar,
  Backbone,
  Code,
  ConsumerInsight,
  Css3,
  Contentful,
  Expand,
  Figma,
  Gatsby,
  Html,
  Email,
  Linkedin,
  Javascript,
  Jest,
  Github,
  NewYork,
  Woman,
  React,
  Ruby,
  Qualitative,
  Sass,
  Selenium,
  Sketch,
  Storybook,
  Strapi
}