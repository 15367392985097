// expand by Richard Schumann from the Noun Project

import React from 'react';
import Icons from './Icons';

export default function Code({ className, viewBox } = '') {
  return (
    <Icons 
      className={className}
      viewBox={viewBox}
      path={
        (
          <>
            <g><g><path d="M456,424H56V88h400V424z M72,408h368V104H72V408z"/></g></g><g><g><polygon points="136,224 120,224 120,152 192,152 192,168 136,168   "/></g></g><g><g><polygon points="392,360 320,360 320,344 376,344 376,288 392,288   "/></g></g><g><g><rect x="114.745" y="184" transform="matrix(0.7071 0.7071 -0.7071 0.7071 182.6274 -56.9016)" width="90.51" height="16"/></g></g><g><g><rect x="306.745" y="312" transform="matrix(0.7071 0.7071 -0.7071 0.7071 329.3726 -155.1758)" width="90.51" height="16"/></g></g>
          </>
        )
      } 
    />
  )
}
