/* 
Created by Lagot Design from the Noun Project
*/

import React from 'react';
import Icons from './Icons';

export default function Code({ className, viewBox } = '') {
  return (
    <Icons 
      className={className}
      viewBox={viewBox}
      path={
        (
          <path d="M58.87,10,58,9.89V8.5a6,6,0,0,0-6-6H12a6,6,0,0,0-6,6V9.89L5.13,10A6,6,0,0,0,0,16V55.5a6,6,0,0,0,6,6H58a6,6,0,0,0,6-6V16A6,6,0,0,0,58.87,10ZM10,8.5a2,2,0,0,1,2-2H52a2,2,0,0,1,2,2V22.73L35.76,37.41a6,6,0,0,1-7.53,0L10,22.73ZM4,16a2,2,0,0,1,1.71-2L6,13.93v5.58L4,17.9ZM60,55.5a.77.77,0,0,1,0,.15L40.29,41.86,38,45.14,55.65,57.5H8.35L26,45.14l-2.3-3.28L4,55.65a.77.77,0,0,1,0-.15V23L25.73,40.53a10.07,10.07,0,0,0,12.54,0L60,23Zm0-37.6-2,1.61V13.93l.29.05A2,2,0,0,1,60,16Z"/>
        )
      } 
    />
  )
}