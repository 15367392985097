import React from 'react'
import clsx from 'clsx'
import * as styles from './Video.module.scss'

const Video = ({
	className: classNameProp,
	source = '',
	children
}) => {
	const className = clsx(
		'Video',
		styles.Video,
		classNameProp,
	)

	return (
		<div className={className}>
			<iframe 
				src={source}
				frameBorder="0"
				allow="autoplay; fullscreen"
				allowFullScreen>
			</iframe>
		</div>
	)
}

export default Video