import React from 'react';
import Icons from './Icons';

export default function Code({ className, viewBox } = '') {
  return (
    <Icons 
      className={className}
      viewBox={viewBox}
      path={
        (
          <>
            <path d="M21.875 16.361c-.043-.048-1.067-1.18-2.365-1.19c-.68 0-1.288.283-1.815.858c-.773.842-2.35 1.85-4.25 1.921c-1.598.059-3.085-.548-4.423-1.805c-1.644-1.544-2.155-4.016-1.302-6.297c.834-2.23 2.752-3.616 5.131-3.707l.044-.004c.024-.003 2.302-.258 4.325 1.548c.17.185 1.154 1.197 2.475 1.228c.823.018 1.586-.336 2.27-1.055c.602-.632.87-1.342.797-2.112c-.154-1.61-1.806-2.876-2.03-3.04c-.212-.184-1.878-1.578-4.476-2.294c-2.52-.695-6.42-.853-10.685 2.349a7.31 7.31 0 0 0-.557.49c-.28.208-.523.462-.716.753a12.469 12.469 0 0 0-3.064 8.677c.207 6.283 5.265 9.293 5.646 9.51c.262.17 2.906 1.81 6.495 1.809c2.106 0 4.538-.565 7.005-2.322c.248-.138 1.714-1.012 2.103-2.52c.23-.894.042-1.815-.562-2.737l-.046-.06zm-16.932 1.97c0-1.09.887-1.977 1.977-1.977s1.977.886 1.977 1.977c0 1.09-.887 1.977-1.977 1.977s-1.977-.887-1.977-1.977zm.139-13.657c.236-.275.451-.498.628-.67a1.965 1.965 0 0 1 1.088-.329c1.09 0 1.977.887 1.977 1.977S7.888 7.63 6.798 7.63s-1.977-.887-1.977-1.977c0-.356.096-.69.261-.978zM13.249.999c3.954 0 6.657 2.336 6.826 2.486l.043.034c.42.3 1.532 1.301 1.63 2.324c.044.469-.126.898-.52 1.313c-.477.5-.983.752-1.504.738c-.964-.019-1.743-.887-1.76-.905l-.042-.044c-2.292-2.063-4.83-1.855-5.13-1.822a6.82 6.82 0 0 0-3.012.818a3 3 0 0 0-2.34-3.214C9.543 1.45 11.516.999 13.248.999zM3.884 6.34a3 3 0 0 0 2.914 2.31c.122 0 .24-.01.358-.024a7.336 7.336 0 0 0-.39.866c-.75 2.003-.59 4.14.359 5.854c-.068-.005-.136-.01-.205-.01a2.999 2.999 0 0 0-2.967 2.6a10.075 10.075 0 0 1-1.7-5.288a11.43 11.43 0 0 1 1.63-6.309zM21.497 18.9c-.3 1.174-1.615 1.89-1.627 1.896l-.058.036c-6.287 4.499-12.137.667-12.382.502l-.036-.022a2.848 2.848 0 0 1-.034-.02a2.998 2.998 0 0 0 2.543-3.228c1.124.64 2.336.951 3.58.906c2.214-.083 4.057-1.264 4.962-2.25c.327-.356.67-.53 1.048-.53h.005c.762.004 1.46.688 1.593.826c.421.658.558 1.291.406 1.884z"/><rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
          </>
        )
      } 
    />
  )
}